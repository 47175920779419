import ProductItem from "./product-item.model";

export default class Product {
  billPaymentProductId!: string;
  billerProductId!: string;
  billPaymentProductName!: string;
  isAmountFixed!: boolean;
  amount!: number;
  currency!: string;
  productItems!: ProductItem[];
  enableQty!: boolean;

  constructor(dto: any) {
    if (dto) {
      this.billPaymentProductId = dto.billPaymentProductId;
      this.billerProductId = dto.billerProductId;
      this.billPaymentProductName = dto.billPaymentProductName;
      this.isAmountFixed = dto.isAmountFixed;
      this.amount = dto.amount;
      this.currency = dto.currency;
      this.productItems =
        dto.productItems && dto.productItems.length > 0
          ? dto.productItems.map((x: any) => new ProductItem(x))
          : [];
      this.enableQty = dto.enableQuantity;
    }
  }
}
