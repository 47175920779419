export default class RrrData {
  rrr!: string;
  billerName!: string;
  serviceName!: string;
  amount!: number;
  status!: string;
  firstName!: string;
  lastName!: string;
  email!: string;
  phone!: string;
  naration!: string;

  constructor(dto: any = null) {
    if (dto) {
      this.rrr = dto.rrr
      this.billerName = dto.billerName
      this.serviceName = dto.serviceName
      this.amount = dto.amount
      this.status = dto.status
      this.firstName = dto.firstName
      this.lastName = dto.lastName
      this.email = dto.email
      this.phone = dto.phone
      this.naration = dto.naration
    }
  }
}