export default class ConfigData {
  splitRrrConfigId!: number;
  organizationId!: number;
  logoUrl!: string;
  brandName!: string;
  heroTitle!: string;
  organizationName!: string;
  organizationCode!: string;

  constructor(dto: any = null) {
    if (dto) {
      this.splitRrrConfigId = dto.splitRrrConfigId
      this.organizationId = dto.organizationId
      this.logoUrl = dto.logoUrl
      this.brandName = dto.brandName
      this.heroTitle = dto.heroTitle
      this.organizationName = dto.organizationName
      this.organizationCode = dto.organizationCode
    }
  }
}