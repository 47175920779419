import React from "react";
import useGlobalData from "../../core/hooks/useGlobalData";
import { Link } from "react-router-dom";

const Header = () => {
  const { configData } = useGlobalData();

  return (
    <header
      id="header-sticky"
      className="header-transparent sticky-bar header-green"
    >
      <div className="header-area">
        <div className="container">
          <div className="position-relative">
            <div className="row align-items-center">
              <div className="col-xl-2 col-lg-2">
                <div className="logo">
                  <a href="/">
                    <img
                      src={configData?.logoUrl}
                      style={{ width: "50px", height: "auto" }}
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="col-xl-8 col-lg-8 position-static">
                <div className="main-menu text-center">
                  <nav id="mobile-menu">
                    <ul>
                      <li>
                        <Link to="/">
                          <span>Home</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/generate-rrr">
                          <span>Generate RRR</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/payment-status">
                          <span>Check Payment Status</span>
                        </Link>
                      </li>
                      <li className="d-block d-md-none">
                        <Link to="https://app.flopay.net">
                          <span>Sign In</span>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="mobile-menu"></div>
              </div>
              <div className="col-xl-2 col-lg-2 d-none d-lg-block">
                <div className="header-btn text-right">
                  <Link to={"https://app.flopay.net"}>
                    <button
                      type="button"
                      className="btn-cart d-flex align-items-center"
                    >
                      Sign In
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
