import React from "react";
import { Controller } from "react-hook-form";

interface NumberInputProps {
  name: string;
  control: any;
  rules: any;
  defaultValue?: number;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  isRequired?: boolean;
  onChangeInput?: (value: string) => void;
}

const NumberInput: React.FC<NumberInputProps> = React.memo(
  ({
    name,
    control,
    rules,
    defaultValue,
    placeholder,
    label,
    disabled,
    isRequired,
    onChangeInput,
  }: NumberInputProps) => {
    return (
      <>
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({
            field: { onChange, value, name },
            fieldState: { error },
          }: any) => {
            return (
              <>
                {label && (
                  <label>
                    {label}
                    <>{isRequired && <span className="required">*</span>}</>
                  </label>
                )}

                <input
                  type={"number"}
                  placeholder={placeholder}
                  disabled={disabled}
                  value={value}
                  onChange={(e) => {
                    if (onChangeInput) onChangeInput(e.target.value);

                    onChange(e);
                  }}
                  className="mb-0 px-2"
                  style={{ height: "40px", width: "80px" }}
                />

                {error?.message && (
                  <p className="text-red-500 ajax-response error">
                    {error?.message}
                  </p>
                )}
              </>
            );
          }}
        />
      </>
    );
  }
);

export default NumberInput;
