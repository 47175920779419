import React from "react";
import { Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";

interface FormSelectFilterInputProps {
  name: string;
  control: any;
  options: any;
  rules: any;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  setValue: any;
}

export default function FormSelectFilterInput({
  name,
  control,
  rules,
  defaultValue,
  options,
  placeholder,
  label,
  disabled,
  setValue,
}: FormSelectFilterInputProps) {
  return (
    <div className="w-full mb-2">
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({
          field: { value, onChange },
          fieldState: { error },
        }: any) => (
          <div className="country-select">
            <label>
              {label} <span className="required">*</span>
            </label>

            <Dropdown
              id={name}
              value={value}
              onChange={(e) => onChange(e.value)}
              options={options}
              optionLabel="label"
              placeholder={placeholder}
              filter
              // valueTemplate={selectedCountryTemplate}
              // itemTemplate={countryOptionTemplate}
              // className="w-full"
              style={{ width: "100%" }}
            />

            <small className="p-error text-[10px] font-sans">
              {error?.message}
            </small>
          </div>
        )}
      />
    </div>
  );
}
