import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import logoUrl from "../../assets/img/logo/yobe_logo.png";
import { format } from "date-fns";

const generateReceiptHtml = (
  data: any,
  heroTitle: string,
  renderFor: string = "rrr"
) => {
  return `<!DOCTYPE html>
            <html lang="en">
              <head>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Document</title>
                <style>
                  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
                  body {
                    font-family: "Rubik", sans-serif;
                    font-weight: normal;
                    font-style: normal;
                    color: #7b8491;
                    font-size: 15px;
                  }
                  *,
                  ::after,
                  ::before {
                    box-sizing: border-box;
                  }
            
                  h1,
                  h2,
                  h3,
                  h4,
                  h5,
                  h6,
                  th,
                  th,
                  p {
                    font-family: "Poppins", sans-serif;
                  }
                  h4 {
                    font-size: 22px;
                    color: #233d63;
                    font-style: normal;
                    font-weight: 600;
                    text-transform: normal;
                    line-height: 1.2;
                  }
                  h6 {
                    font-size: 16px;
                    margin: 0;
                  }
                  .table {
                    width: 100%;
                    max-width: 100%;
                    margin-bottom: 1rem;
                    background-color: transparent;
                  }
                  tbody {
                    display: table-row-group;
                    vertical-align: middle;
                    border-color: inherit;
                  }
                  tr {
                    display: table-row;
                    vertical-align: inherit;
                    border-color: inherit;
                  }
                  .table td,
                  .table th {
                    border-top: 1px solid #eaedff;
                    padding: 0.75rem;
                    vertical-align: top;
                    border-top: 1px solid #dee2e6;
                  }
                  th {
                    text-align: inherit;
                  }
                  .d-flex {
                    display: flex !important;
                  }
            
                  .p-4 {
                    padding: 1.5rem !important;
                  }
                  .p-2 {
                    padding: 0.5rem !important;
                  }
                  .ml-2 {
                    margin-left: 0.5rem !important;
                  }
                  .my-0 {
                    margin-top: 0 !important;
                    margin-bottom: 0 !important;
                  }
                  .mt-4 {
                    margin-top: 1.5rem !important;
                  }
                  .my-4 {
                    margin-top: 1.5rem !important;
                    margin-bottom: 1.5rem !important;
                  }
                  .border {
                    border: 1px solid #dee2e6 !important;
                  }
            
                  .align-items-center {
                    align-items: center !important;
                  }
                  .justify-content-center {
                    justify-content: center !important;
                  }
                  .border-top {
                    border-top: 1px solid #dee2e6 !important;
                  }
                  .font-weight-bold {
                    font-weight: 700 !important;
                  }
                  p {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 28px;
                    color: #7b8491;
                    margin-bottom: 15px;
                    transition: 0.3s;
                    margin-top: 0;
                  }
                </style>
              </head>
              <body>
                <div class="border p-4" style="width: 445px">
                  <div class="mb-4 pb-2 d-flex align-items-center border-bottom">
                    <img
                      src="${logoUrl}"
                      style="width: 50px; height: auto"
                      alt=""
                    />
                    <h4 class="wow fadeInUp animated ml-2 my-0" data-wow-delay="0.4s">
                      <span>${heroTitle}</span>
                    </h4>
                  </div>
                  ${
                    renderFor === "payment"
                      ? renderForPayment(data)
                      : renderFor === "status"
                      ? renderForStatus(data)
                      : renderForRrr(data)
                  }
                  <div
                    class="mt-4 border-top p-2 d-flex align-items-center justify-content-center"
                  >
                    <p class="font-weight-bold">Powered by Flopay</p>
                  </div>
                </div>
              </body>
            </html>
  `;
};

const renderForRrr = (data: any) => {
  return `<div class="d-flex my-4">
            <h6>RRR Receipt</h6>
          </div>
          <table class="table">
            <tbody>
              <tr>
                <th>MDA</th>
                <td>${data.billerName}</td>
              </tr>
              <tr>
                <th>Service</th>
                <td>${data.serviceName}</td>
              </tr>
              <tr>
                <th>Amount</th>
                <td>
                  <span>NGN</span>
                  <span style="margin-left: 6px">${data.amount}</span>
                </td>
              </tr>
              <tr>
                <th>RRR</th>
                <td>${data.rrr}</td>
              </tr>
              <tr>
                <th>Payer Name</th>
                <td>
                  <span>${data.firstName}</span>
                  <span style="margin-left: 6px">${data.lastName}</span>
                </td>
              </tr>
              <tr>
                <th>Phone No</th>
                <td>${data.phone}</td>
              </tr>
              <tr>
                <th>Payer Email</th>
                <td>${data.email}</td>
              </tr>
              <tr>
                <th>Desc</th>
                <td>${data.naration}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>${data.status}</td>
              </tr>
              
            </tbody>
          </table>`;
};

const renderForPayment = (data: any) => {
  return `<div class="d-flex my-4">
            <h6>Payment Receipt</h6>
          </div>
          <table class="table">
            <tbody>
              <tr>
                <th>MDA</th>
                <td>${data.billerName}</td>
              </tr>
              <tr>
                <th>Service</th>
                <td>${data.serviceName}</td>
              </tr>
              <tr>
                <th>Amount</th>
                <td>
                  <span>NGN</span>
                  <span style="margin-left: 6px">${data.amount}</span>
                </td>
              </tr>
              <tr>
                <th>Gateway Fee</th>
                <td>
                  <span>NGN</span>
                  <span style="margin-left: 6px">${data.paymentGatewayFee}</span>
                </td>
              </tr>
              <tr>
                <th>RRR</th>
                <td>${data.rrr}</td>
              </tr>
              <tr>
                <th>Payer Name</th>
                <td> 
                  <span>${data.firstName}</span>
                  <span style="margin-left: 6px">${data.lastName}</span>
                </td>
              </tr>
              <tr>
                <th>Phone No</th>
                <td>${data.phone}</td>
              </tr>
              <tr>
                <th>Payer Email</th>
                <td>${data.email}</td>
              </tr>
              <tr>
                <th>Trans ID</th>
                <td>${data.transactionId}</td>
              </tr>
              <tr>
                <th>Payment Ref</th>
                <td>${data.paymentReference}</td>
              </tr>
              <tr>
                <th>Desc</th>
                <td>${data.naration}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>${data.status}</td>
              </tr>
              
            </tbody>
          </table>`;
};

const renderForStatus = (data: any) => {
  return `<div class="d-flex my-4">
            <h6>Payment Status Summary</h6>
          </div>
          <table class="table">
            <tbody>
              <tr>
                <th>Order ID</th>
                <td>${data.orderId}</td>
              </tr>
              <tr>
                <th>Amount</th>
                <td>
                  <span>NGN</span>
                  <span style="margin-left: 6px">${data.amount}</span>
                </td>
              </tr>
              <tr>
                <th>RRR</th>
                <td>${data.rrr}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>${data.message}</td>
              </tr>
              <tr>
                <th>Time</th>
                <td>${formatDateTime(data.transactionTime, true)}</td>
              </tr>            
            </tbody>
          </table>`;
};

const htmlStringToPdf = async (htmlString: string, pdfFor: string = "rrr") => {
  let iframe = document.createElement("iframe");
  iframe.style.visibility = "hidden";
  document.body.appendChild(iframe);

  //@ts-ignore
  let iframedoc: any = iframe.contentDocument || iframe.contentWindow.document;
  iframedoc.body.innerHTML = htmlString;

  let canvas = await html2canvas(iframedoc.body, {});

  // console.log('canvas :>> ', canvas);

  // Convert the iframe into a PNG image using canvas.
  let imgData = canvas.toDataURL("image/png");

  // Create a PDF document and add the image as a page.
  const pdf = new JsPDF({
    format: "a4",
    unit: "mm",
  });
  pdf.addImage(imgData, "PNG", 0, 0, 210, 297);

  pdf.save(
    pdfFor === "payment"
      ? "payment-receipt.pdf"
      : pdfFor === "status"
      ? "payment-status-summary.pdf"
      : "rrr-receipt.pdf"
  );

  // Get the file as blob output.
  // let blob = pdf.output("blob");

  // Remove the iframe from the document when the file is generated.
  document.body.removeChild(iframe);
};

const formatDateTime = (date: Date | string, includeTime: boolean = false) => {
  if (!date) return "";
  const localDatetime = new Date(date).toLocaleString();
  const pattern = includeTime ? "dd/MM/yyyy hh:mm a" : "dd/MM/yyy";
  return format(new Date(localDatetime), pattern);
};

const priceFormater = (price: number | undefined) => {
  if (!price) return 0;
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const helpers = {
  generateReceiptHtml,
  htmlStringToPdf,
  formatDateTime,
  priceFormater,
};
export default helpers;
