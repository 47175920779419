import React from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { SelectItem } from "../../core/interfaces/select-item.interface";
import rrrGeneratorService from "../../core/service/rrr-generator.service";
import Biller from "../../core/models/biller.model";
import BillerProduct from "../../core/models/biller-product.model";
import Product from "../../core/models/product.model";
import FormInput from "../../components/Form/FormInput/FormInput";
import FormTextArea from "../../components/Form/FormTextArea/FormTextArea";
import { PaymentFormTypes } from "../../core/types/form-types";
import FormButton from "../../components/Form/FormButton/FormButton";
import RemitaPayment from "react-remita";
import useGlobalData from "../../core/hooks/useGlobalData";
import jsPDF from "jspdf";
import "jspdf-autotable";
import helpers from "../../core/utils/helpers";
import RrrData from "../../core/models/rrr-data.model";
import SuccessData from "../../core/models/success-data.model";
import ConfigData from "../../core/models/config-data.model";
import FormSelectFilterInput from "../../components/Form/FormSelectFilterInput/FormSelectFilterInput";
import ClipLoader from "react-spinners/ClipLoader";
import NumberInput from "../../components/Form/NumberInput/NumberInput";
import { FaRegSquare, FaCheckSquare } from "react-icons/fa";
import ProductItem from "../../core/models/product-item.model";

const GenerateRrr = () => {
  const { configData }: { configData: ConfigData } = useGlobalData();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [billers, setBillers] = React.useState<Biller[]>([]);
  const [billerProduct, setBillerProduct] = React.useState<BillerProduct>(
    new BillerProduct()
  );
  const [products, setProducts] = React.useState<Product[]>([]);
  const [billersSelectItems, setBillersSelectItems] = React.useState<
    SelectItem[]
  >([]);
  const [billerProductsSelectItems, setBillerProductsSelectItems] =
    React.useState<SelectItem[]>([]);
  const [selectedProduct, setSelectedProduct] = React.useState<Product>(
    new Product(null)
  );
  const [successData, setSuccessData] = React.useState<SuccessData>(
    new SuccessData(null)
  );
  const [rrrData, setRrrData] = React.useState<RrrData>(new RrrData(null));
  const [isgenerated, setIsGenerated] = React.useState<boolean>(false);
  const [paymentSuccessful, setPaymentSuccessful] =
    React.useState<boolean>(false);
  const [errorData, setErrorData] = React.useState<string>("");
  const isRemitaPaymentLive =
    process.env.REACT_APP_API_REMITA_ISLIVE === "true";
  const [paymentData, setpaymentData] = React.useState({
    key: "", // enter your key here
    processRrr: true,
    narration: "",
    firstName: "",
    lastName: "",
    email: "",
    amount: 0,
    extendedData: {
      customFields: [
        {
          name: "rrr",
          value: "",
        },
      ],
    },
  });
  const [getBillerProductsLoading, setGetBillerProductsLoading] =
    React.useState<boolean>(false);

  const [productItems, setProductItems] = React.useState<ProductItem[]>([]);
  const [items, setItems] = React.useState<ProductItem[]>([]);
  const [selectedOptions, setSelectedOptions] = React.useState<number[]>([]);
  const [prodctHasItems, setProductHasItems] = React.useState<boolean>(false);
  const [totalAmount, setTotalAmount] = React.useState<number>(0);

  const { control, setValue, handleSubmit, reset, watch } = useForm<any>();

  const selectedBillerId = watch("biller");
  const selectedProductId = watch("product");

  React.useEffect(() => {
    if (configData) initLoad();
  }, [configData]);

  React.useEffect(() => {
    if (selectedBillerId) getBillerProducts(selectedBillerId);
  }, [selectedBillerId]);

  React.useEffect(() => {
    if (selectedProductId) {
      clearUp();
      productChange(selectedProductId);
    }
  }, [selectedProductId]);

  React.useEffect(() => getTotalAmount(items), [items]);

  const initLoad = async () => {
    const apiCalls = [getBillers()];
    Promise.all(apiCalls).finally(() => {});
  };

  const getBillers = async () => {
    const billerData: Biller[] = await rrrGeneratorService.getRemitaBillers(
      configData?.organizationCode
    );
    if (billerData && billerData.length > 0) {
      const billersSelectItemsData: any = billerData.map((x: Biller) => {
        return { label: x.billerName, value: x.remitaBillerId };
      });
      setBillers(billerData);
      setBillersSelectItems(billersSelectItemsData);
    }

    setLoading(false);
  };

  const clearUp = () => {
    // clean up amount inputs
    // productItems &&
    //   productItems.length > 0 &&
    //   productItems.forEach((x, index) => {
    //     // @ts-ignore
    //     setValue(`unitPrice${index}`, "");
    //   });

    // clean up state variables
    setSelectedProduct(new Product(null));
    setProductHasItems(false);
    setSelectedOptions([]);
    setProductItems([]);
    setTotalAmount(0);
    setItems([]);
  };

  const getBillerProducts = async (billerId: string) => {
    setGetBillerProductsLoading(true);
    setProducts([]);
    clearUp();

    const billerProductData: BillerProduct =
      await rrrGeneratorService.getBillerProducts(billerId);
    if (billerProductData.billerId) {
      const billerProductsSelectItemsData: any = billerProductData.products.map(
        (x: Product) => {
          return {
            label: x.billPaymentProductName,
            value: x.billPaymentProductId,
          };
        }
      );
      setBillerProduct(billerProductData);
      setProducts(billerProductData.products);
      setBillerProductsSelectItems(billerProductsSelectItemsData);
    }

    setGetBillerProductsLoading(false);
  };

  const productChange = (productId: string) => {
    const _selectedProduct = products.find(
      (x) => x.billPaymentProductId === productId
    );

    if (!_selectedProduct) return;

    if (
      _selectedProduct?.productItems &&
      _selectedProduct.productItems.length > 0
    ) {
      // update rendered unitPrice and qty inputs
      _selectedProduct?.productItems.forEach((x, index) => {
        setValue(`unitPrice${index}`, x.amount);
        setValue(`qty${index}`, 1);
        x.unitPrice = x.amount;
      });

      setProductHasItems(true);
      setProductItems(_selectedProduct?.productItems);
    }

    setSelectedProduct(_selectedProduct);

    // update selected product inputs when selected product has no items
    setValue("currency", _selectedProduct.currency);
    setValue("productName", _selectedProduct.billPaymentProductName);
    setValue("product", _selectedProduct.billPaymentProductId);
    setValue("amount", _selectedProduct.amount);
  };

  const getBillerName = (id: string): string => {
    let name: string = "";
    billers.filter((biller) => {
      if (biller.billerId === id) name = biller.billerName;
    });
    return name;
  };

  const onSubmit = async (formData: any) => {
    setSuccessData(new SuccessData(null));
    setRrrData(new RrrData(null));
    setErrorData("");

    // validate products with items
    if (prodctHasItems && items.length <= 0) {
      setErrorData("Please select at least one service item");
      return;
    }

    //  manually validate amount value of each item
    if (items && items.length > 0) {
      const errorMessages: string[] = [];

      items.forEach((x, index) => {
        if (x.amount <= 0) {
          errorMessages.push(`Add amount to pay for <b>${x.name}</b>.`);
        }
      });

      if (errorMessages.length > 0) {
        setErrorData(errorMessages.join(`<br />`));
        return;
      }
    }

    setSubmitting(true);
    formData.billerName = getBillerName(formData.biller);

    // stringify all selected items names
    // join all selected items names as descripion for remitta
    const _selectedItemsNames: string[] = [];
    items.forEach((x) => {
      _selectedItemsNames.push(x.name);
    });
    const itemsDescription: string = _selectedItemsNames.join(", ");

    const dto = {
      organizationId: configData?.organizationId,
      billerId: formData.biller,
      billerName: getBillerName(formData.biller),
      serviceTypeId: formData.product,
      serviceName: formData.productName,
      payerName: formData.payerName,
      payerEmail: formData.payerEmail,
      payerPhone: formData.payerPhone,
      description: !prodctHasItems ? formData.description : itemsDescription,
      amount: !prodctHasItems ? +formData.amount : totalAmount,
      billerProductItems: !prodctHasItems ? null : items,
    };

    const { ok, data, status, problem }: any =
      await rrrGeneratorService.createCollection(dto);

    if (ok && status === 200) {
      setSelectedProduct(new Product(null));
      reset({});

      const customerNames = dto.payerName.split(" ") || [];

      scrollToTop();
      setIsGenerated(true);
      setRrrData({
        rrr: data?.data?.rrr,
        billerName: data?.data?.billerName,
        amount: data?.data?.amount,
        serviceName: data?.data?.serviceName,
        status: data?.data?.status,
        email: dto?.payerEmail,
        phone: dto?.payerPhone,
        firstName: customerNames.length > 0 ? customerNames[0] : "",
        lastName: customerNames.length > 1 ? customerNames[1] : "",
        naration: dto.description,
      });

      setpaymentData({
        key: "WU9CRVNHfDk1NTAwNzc4NzV8MzQ0MWYyYzUwNjA0MGIzYTVhNDQ1YTE1ZWM4MDQ4Zjk3Yjk5OTMwMDVmYTM2NjNiNjg3NTE2Y2RlOTk3ZTZjZmJiZjhlMTQ1YzZjZGZmZDlkYzY3ZGUyZDFkODRlNDM4NDAzM2Q3ODgyMTgzZTM0ODAxNTQzMjM3MGZlOWFmZjA=", // enter your key here
        processRrr: true,
        narration: dto.description,
        firstName: customerNames.length > 0 ? customerNames[0] : "",
        lastName: customerNames.length > 1 ? customerNames[1] : "",
        email: dto?.payerEmail,
        amount: data?.data?.amount,
        extendedData: {
          customFields: [
            {
              name: "rrr",
              value: data?.data?.rrr,
            },
          ],
        },
      });
    } else if (problem === "CLIENT_ERROR") {
      setErrorData(
        data?.title ||
          data?.errors ||
          data.error ||
          "Unexpected error, Please try again!"
      );
    } else if (!ok && data.error) {
      setErrorData(data?.error);
    } else {
      setErrorData("Unexpected error, Please try again!");
    }

    setSubmitting(false);
  };

  let remitaData = {
    ...paymentData,
    onSuccess: function (response: any) {
      // function callback when payment is successful
      console.log("callback Successful Response", response);

      // set success data
      setSuccessData({
        rrr: rrrData?.rrr,
        billerName: rrrData?.billerName,
        amount: rrrData?.amount,
        paymentGatewayFee: response.amount - rrrData?.amount,
        serviceName: rrrData?.serviceName,
        status: "Payment Successful",
        email: rrrData?.email,
        phone: rrrData?.phone,
        firstName: rrrData?.firstName,
        lastName: rrrData?.lastName,
        naration: rrrData?.naration,
        transactionId: response.transactionId,
        paymentReference: response.paymentReference,
      });

      setPaymentSuccessful(true);
    },
    onError: function (response: any) {
      // function callback when payment fails
      console.log("callback Error Response", response);
    },
    onClose: function () {
      // function callback when payment modal is closed
      console.log("closed");
    },
  };

  const printRrrReceipt = async (data: RrrData, heroTitle: string) => {
    // const pdf = new jsPDF();
    // //@ts-ignore
    // pdf.autoTable({ html: "#rrr-table" });
    // pdf.save("rrr-receipt.pdf");

    const html: string = helpers.generateReceiptHtml(data, heroTitle);
    await helpers.htmlStringToPdf(html);
  };

  const printReceipt = async (data: SuccessData, heroTitle: string) => {
    const html: string = helpers.generateReceiptHtml(
      data,
      heroTitle,
      "payment"
    );
    await helpers.htmlStringToPdf(html, "payment");
  };

  const onSelectProduct = (item: ProductItem, index: number) => {
    const indexOfItemName = items.findIndex((x) => x.name === item.name);

    const updatedItems =
      indexOfItemName > -1
        ? items.filter((x, i) => i !== indexOfItemName)
        : [
            ...items.slice(0, index),
            { ...item, unitPrice: item.amount },
            ...items.slice(index),
          ];

    setItems(updatedItems);
    getTotalAmount(updatedItems);

    toggleSelectedOption(index);
  };

  const toggleSelectedOption = (itemIndex: number) =>
    setSelectedOptions((selectedOptions) => {
      if (!selectedOptions.includes(itemIndex)) {
        return [...selectedOptions, itemIndex];
      } else {
        return selectedOptions.filter((index) => index !== itemIndex);
      }
    });

  const isSelected = (itemIndex: number): boolean => {
    return !selectedOptions.includes(itemIndex) ? false : true;
  };

  const getTotalAmount = (items: ProductItem[]) => {
    const sum = items.reduce((total, item) => total + item.amount, 0);
    setTotalAmount(sum);
  };

  const onAmountChange = (amountValue: number, itemName: string) =>
    setItems((prevItems) =>
      prevItems.map((item, index) =>
        item.name === itemName
          ? {
              ...item,
              amount: amountValue * item.quantity,
              quantity: item.quantity,
              unitPrice: amountValue,
            }
          : item
      )
    );

  const onQtyChange = (quantityValue: number, itemName: string) =>
    setItems((prevItems) =>
      prevItems.map((item, index) =>
        item.name === itemName
          ? {
              ...item,
              amount: item.unitPrice * +quantityValue,
              quantity: +quantityValue,
            }
          : item
      )
    );

  const resetItem = (itemName: string) => {
    setItems((prevItems) =>
      prevItems.map((item, index) =>
        item.name === itemName
          ? new ProductItem({ ...item, amount: item.unitPrice, quantity: 1 })
          : item
      )
    );
  };

  const getItemAmount = (itemName: string) => {
    const indexOfItemName: number = items
      .map((x: ProductItem) => x.name)
      .indexOf(itemName);

    if (indexOfItemName > -1)
      return <>{`₦${helpers.priceFormater(items[indexOfItemName].amount)}`}</>;
  };

  const verifyIfInputIsDiabled = (index: number) => {
    return productItems[index].amount > 0 ? true : false;
  };

  const scrollToTop = () => {
    window &&
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  };

  return (
    <>
      <Helmet>
        <title>Flopay | Generate RRR</title>
        <meta name="description" content="#" />
      </Helmet>
      <main>
        {/* Generate RRR Form */}
        <section className="login-area pt-100 pb-100">
          <div className="container">
            {!isgenerated && !paymentSuccessful && (
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="basic-login">
                    <div className="checkbox-form">
                      <h3>Generate RRR</h3>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <h6>Search Service</h6>
                        </div>

                        <div className="col-md-12">
                          <FormSelectFilterInput
                            name={"biller"}
                            control={control}
                            label={"Select Biller"}
                            setValue={setValue}
                            options={billersSelectItems}
                            rules={{
                              required: "Please select Biller",
                            }}
                          />
                        </div>

                        {!getBillerProductsLoading ? (
                          <>
                            {billerProductsSelectItems.length > 0 && (
                              <div className="col-md-12">
                                <FormSelectFilterInput
                                  name={"product"}
                                  control={control}
                                  label={"Select Biller Product"}
                                  setValue={setValue}
                                  options={billerProductsSelectItems}
                                  rules={{
                                    required: "Please select Biller Product",
                                  }}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="d-flex align-items-center justify-content-center w-100">
                            <ClipLoader
                              color={"#ffffff"}
                              loading={true}
                              cssOverride={
                                {
                                  display: "flex",
                                  borderColor: "#00c867",
                                } as React.CSSProperties
                              }
                              size={20}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          </div>
                        )}

                        {prodctHasItems ? (
                          <div>
                            {productItems && productItems.length > 0 && (
                              <div className="col-md-12">
                                <div className="table-responsive">
                                  <div className="d-flex align-items-center mb-2">
                                    <h6 className="mb-0">Service Items</h6>
                                  </div>
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Item Name</th>
                                        <th>Currency</th>
                                        <th>Unit Price</th>
                                        <th>Qty</th>
                                        <th>Amount</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {productItems.map(
                                        (item: ProductItem, index: number) => (
                                          <tr key={index.toString()}>
                                            <td className="">
                                              {!isSelected(index) ? (
                                                <FaRegSquare
                                                  onClick={() => {
                                                    // update qty
                                                    setValue(
                                                      `qty${index}`,
                                                      "1"
                                                    );
                                                    // add item to product items Array
                                                    onSelectProduct(
                                                      item,
                                                      index
                                                    );
                                                  }}
                                                />
                                              ) : (
                                                <FaCheckSquare
                                                  onClick={() => {
                                                    // reset item amont if item is unselected
                                                    resetItem(item.name);
                                                    //reset Qty input field
                                                    setValue(
                                                      `qty${index}`,
                                                      "0"
                                                    );
                                                    //
                                                    if (!item.amountAvailable)
                                                      setValue(
                                                        `unitPrice${index}`,
                                                        "0"
                                                      );
                                                    // remove item from items Array
                                                    onSelectProduct(
                                                      item,
                                                      index
                                                    );
                                                  }}
                                                />
                                              )}
                                            </td>
                                            <td
                                              style={{
                                                maxWidth: "150px",
                                                wordWrap: "break-word",
                                              }}
                                            >
                                              {item.name}
                                            </td>
                                            <td className="">
                                              {selectedProduct.currency}
                                            </td>
                                            <td className="">
                                              {item.unitPrice > 0 ? (
                                                <p>{item.unitPrice}</p>
                                              ) : (
                                                <NumberInput
                                                  name={`unitPrice${index}`}
                                                  defaultValue={item.unitPrice}
                                                  control={control}
                                                  disabled={
                                                    !isSelected(index)
                                                      ? true
                                                      : verifyIfInputIsDiabled(
                                                          index
                                                        )
                                                      ? true
                                                      : false
                                                  }
                                                  onChangeInput={(value) => {
                                                    onAmountChange(
                                                      +value,
                                                      item.name
                                                    );
                                                  }}
                                                  placeholder={"0"}
                                                  isRequired
                                                  rules={{}}
                                                />
                                              )}
                                            </td>

                                            <td className="">
                                              <NumberInput
                                                name={`qty${index}`}
                                                defaultValue={item.quantity}
                                                control={control}
                                                disabled={
                                                  !isSelected(index) ||
                                                  !selectedProduct.enableQty
                                                    ? true
                                                    : false
                                                }
                                                onChangeInput={(value) => {
                                                  onQtyChange(
                                                    +value,
                                                    item.name
                                                  );
                                                }}
                                                placeholder={"1"}
                                                isRequired
                                                rules={{}}
                                              />
                                            </td>

                                            <td className="">
                                              <p>
                                                {getItemAmount(item.name) || ""}
                                              </p>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                          <div style={{ fontWeight: "bold" }}>
                                            Total
                                          </div>
                                        </td>
                                        <td>
                                          <div style={{ fontWeight: "bold" }}>
                                            {`₦${helpers.priceFormater(
                                              totalAmount
                                            )}`}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <>
                            {selectedProduct.billPaymentProductId && (
                              <>
                                <div className="col-md-12 mb-2">
                                  <h6>Payment Details</h6>
                                </div>
                                {selectedProduct.amount > 0 && (
                                  <div className="col-md-12">
                                    <p>{`Amount to pay is ₦${helpers.priceFormater(
                                      selectedProduct.amount
                                    )}`}</p>
                                  </div>
                                )}

                                <div className="col-md-12">
                                  <FormInput
                                    name={"productName"}
                                    control={control}
                                    defaultValue={
                                      selectedProduct.billPaymentProductName
                                    }
                                    label={"Product Name"}
                                    disabled={true}
                                    placeholder={"Product Name"}
                                    isRequired
                                    type={"text"}
                                    rules={{
                                      required: "Product is required",
                                    }}
                                  />
                                </div>

                                <div className="col-md-6">
                                  <FormInput
                                    name={"currency"}
                                    control={control}
                                    defaultValue={selectedProduct.currency}
                                    label={"Currency"}
                                    disabled={true}
                                    placeholder={"Currency"}
                                    isRequired
                                    type={"text"}
                                    rules={{
                                      required: "Currency is required",
                                    }}
                                  />
                                </div>

                                <div className="col-md-6">
                                  <FormInput
                                    name={"amount"}
                                    control={control}
                                    defaultValue={`${selectedProduct.amount}`}
                                    label={"Amount"}
                                    disabled={
                                      +selectedProduct.amount > 0 ? true : false
                                    }
                                    placeholder={"Amount"}
                                    isRequired
                                    type={"number"}
                                    rules={{
                                      required: "Amount is required",
                                      validate: (value: any) =>
                                        value < 500
                                          ? "Amount must not be less than ₦500"
                                          : true,
                                    }}
                                  />
                                </div>
                                <div className="col-md-12">
                                  <FormTextArea
                                    name={"description"}
                                    control={control}
                                    label={"Description"}
                                    placeholder={"Description"}
                                    isRequired
                                    rules={{
                                      required: "Description is required",
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </>
                        )}

                        <div className="col-md-12 mb-3">
                          <h6>Payer Details</h6>
                        </div>
                        <div className="col-md-12">
                          <FormInput
                            name={"payerName"}
                            control={control}
                            label={"Payer Name"}
                            placeholder={"Payer Name"}
                            isRequired
                            type={"text"}
                            rules={{
                              required: "Payer Name is required",
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <FormInput
                            name={"payerEmail"}
                            control={control}
                            label={"Payer Email"}
                            placeholder={"Payer Email"}
                            isRequired
                            type={"email"}
                            rules={{
                              required: "Payer Email is required",
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "invalid email address",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <FormInput
                            name={"payerPhone"}
                            control={control}
                            label={"Payer Phone"}
                            placeholder={"Payer Phone"}
                            isRequired
                            type={"number"}
                            rules={{
                              required: "Payer Phone is required",
                            }}
                          />
                        </div>

                        {errorData && (
                          <div className="col-md-12">
                            <div
                              className="alert alert-danger"
                              role="alert"
                              dangerouslySetInnerHTML={{ __html: errorData }}
                            />
                          </div>
                        )}
                        <div className="order-button-payment mt-20">
                          <FormButton
                            submitting={submitting}
                            onClick={handleSubmit(onSubmit)}
                            label={"Generate RRR"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* show if rrr is successfully generated */}
            {isgenerated && !paymentSuccessful && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="basic-login">
                    <div className="row">
                      <div className="col-md-6">
                        <h6 className="text-success">{`Payment Successfully Generated`}</h6>
                        <p className=" font-weight-bold mb-1">
                          Payment Instructions
                        </p>

                        <p
                          style={{
                            fontSize: "12px",
                            lineHeight: "14px",
                            marginRight: "32px",
                          }}
                          className=""
                        >{`1. You can walk into any Nigeria bank and present your RRR: ${rrrData?.rrr} to make payment`}</p>
                        <p
                          style={{ fontSize: "12px", lineHeight: "12px" }}
                          className=""
                        >{`2. If you wish to make payment online using various payment channels`}</p>

                        <RemitaPayment
                          live={isRemitaPaymentLive}
                          remitaData={remitaData}
                          className="btn" // class to style the button
                          text="Pay with Remita" //text to show on button
                          // add a 'live' prop to use the live urls/keys
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="border p-4">
                          <div className="mb-4 pb-2 d-flex align-items-center border-bottom">
                            <img
                              src={configData?.logoUrl}
                              style={{ width: "50px", height: "auto" }}
                              alt=""
                            />
                            <h4
                              className="wow fadeInUp animated ml-2"
                              data-wow-delay="0.4s"
                            >
                              <span>{configData?.heroTitle}</span>
                            </h4>
                          </div>
                          <div className="d-flex  ">
                            <h6>RRR Receipt</h6>
                          </div>
                          <table className="table" id="rrr-table">
                            <tbody>
                              <tr>
                                <th>MDA</th>
                                <td className="product_weight">
                                  {rrrData?.billerName}
                                </td>
                              </tr>
                              <tr>
                                <th>Service</th>
                                <td className="product_dimensions">
                                  {rrrData?.serviceName}
                                </td>
                              </tr>
                              <tr>
                                <th>Amount</th>
                                <td className="product_dimensions">{`NGN ${rrrData?.amount}`}</td>
                              </tr>
                              <tr>
                                <th>RRR</th>
                                <td className="product_dimensions">
                                  {rrrData?.rrr}
                                </td>
                              </tr>
                              <tr>
                                <th>Payer Name</th>
                                <td className="product_dimensions">{`${rrrData?.firstName} ${rrrData?.lastName}`}</td>
                              </tr>
                              <tr>
                                <th>Phone No</th>
                                <td className="product_dimensions">
                                  {rrrData?.phone}
                                </td>
                              </tr>
                              <tr>
                                <th>Payer Email</th>
                                <td className="product_dimensions">
                                  {rrrData?.email}
                                </td>
                              </tr>
                              <tr>
                                <th>Desc</th>
                                <td className="product_dimensions">
                                  {rrrData?.naration}
                                </td>
                              </tr>
                              <tr>
                                <th>Status</th>
                                <td className="product_dimensions">
                                  {rrrData?.status}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="mt-4 border-top p-2 d-flex align-items-center justify-content-center ">
                            <p className="font-weight-bold">
                              Powered by Flopay
                            </p>
                          </div>
                        </div>
                        <div className="mt-2">
                          <button
                            type="button"
                            className="btn"
                            onClick={() =>
                              printRrrReceipt(rrrData, configData?.heroTitle)
                            }
                          >
                            Print
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* show if payment is successful */}
            {paymentSuccessful && (
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="basic-login">
                    <div className="col-md-12">
                      <div className="my-2 d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn"
                          onClick={() => {
                            setIsGenerated(false);
                            setPaymentSuccessful(false);
                          }}
                        >
                          Generate RRR
                        </button>
                        <button
                          type="button"
                          className="btn"
                          onClick={() =>
                            printReceipt(successData, configData?.heroTitle)
                          }
                        >
                          Print
                        </button>
                      </div>
                      <div className="border p-4">
                        <div className="mb-4 pb-2 d-flex align-items-center border-bottom">
                          <img
                            src={configData?.logoUrl}
                            style={{ width: "50px", height: "auto" }}
                            alt=""
                          />
                          <h4
                            className="wow fadeInUp animated ml-2"
                            data-wow-delay="0.4s"
                          >
                            <span>{configData?.heroTitle}</span>
                          </h4>
                        </div>
                        <div className="d-flex  ">
                          <h6>Payment Receipt</h6>
                        </div>
                        <table className="table" id="rrr-table">
                          <tbody>
                            <tr>
                              <th>MDA</th>
                              <td className="product_weight">
                                {successData?.billerName}
                              </td>
                            </tr>
                            <tr>
                              <th>Service</th>
                              <td className="product_dimensions">
                                {successData?.serviceName}
                              </td>
                            </tr>
                            <tr>
                              <th>Amount</th>
                              <td className="product_dimensions">{`NGN ${successData?.amount}`}</td>
                            </tr>
                            <tr>
                              <th>Gateway Fee</th>
                              <td className="product_dimensions">{`NGN ${successData?.paymentGatewayFee}`}</td>
                            </tr>
                            <tr>
                              <th>RRR</th>
                              <td className="product_dimensions">
                                {successData?.rrr}
                              </td>
                            </tr>
                            <tr>
                              <th>Payer Name</th>
                              <td className="product_dimensions">{`${successData?.firstName} ${successData?.lastName}`}</td>
                            </tr>
                            <tr>
                              <th>Phone No</th>
                              <td className="product_dimensions">
                                {successData?.phone}
                              </td>
                            </tr>
                            <tr>
                              <th>Payer Email</th>
                              <td className="product_dimensions">
                                {successData?.email}
                              </td>
                            </tr>
                            <tr>
                              <th>Trans. Id</th>
                              <td className="product_dimensions">
                                {successData?.transactionId}
                              </td>
                            </tr>
                            <tr>
                              <th>Payment Ref</th>
                              <td className="product_dimensions">
                                {successData?.paymentReference}
                              </td>
                            </tr>
                            <tr>
                              <th>Desc</th>
                              <td className="product_dimensions">
                                {successData?.naration}
                              </td>
                            </tr>
                            <tr>
                              <th>Status</th>
                              <td className="product_dimensions">
                                {successData?.status}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="mt-4 border-top p-2 d-flex align-items-center justify-content-center ">
                          <p className="font-weight-bold">Powered by Flopay</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
    </>
  );
};

export default GenerateRrr;
