import { useContext } from "react";
import rrrGeneratorService from "../service/rrr-generator.service";
import ConfigData from "../models/config-data.model";
import GlobalContext from "../context/global-context";

const useGlobalData = () => {
  const { configData, setConfigData }: any = useContext<ConfigData>(GlobalContext);

  const loadConfigData = async () => {
    const stateCode: any = process.env.REACT_APP_API_ORGANIZATION_CODE;
    const data: ConfigData = await rrrGeneratorService.getConfigData(stateCode);
    if (data) setConfigData(data);
  };

  return { configData, setConfigData, loadConfigData };
};

export default useGlobalData;
