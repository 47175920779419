export default class ProductItem {
  billerProductItemId!: number;
  billerProductId!: number;
  name!: string;
  amount!: number;
  unitPrice!: number;
  amountAvailable!: boolean;
  quantity!: number;

  constructor(dto: any) {
    if (dto) {
      this.billerProductItemId = dto.billerProductItemId;
      this.billerProductId = dto.billerProductId;
      this.name = dto.name;
      this.amount = dto.amount;
      this.unitPrice = dto.unitPrice;
      this.amountAvailable = dto.amount > 0 ? true : false;
      this.quantity = dto.quantity ? dto.quantity : 1;
    }
  }
}
