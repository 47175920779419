import React from "react";
import { Helmet } from "react-helmet";
import FormInput from "../../components/Form/FormInput/FormInput";
import { useForm } from "react-hook-form";
import rrrGeneratorService from "../../core/service/rrr-generator.service";
import TransactionStatus from "../../core/models/transaction-status.model";
import FormButton from "../../components/Form/FormButton/FormButton";
import helpers from "../../core/utils/helpers";
import useGlobalData from "../../core/hooks/useGlobalData";

const PaymentStatus = () => {
  const { configData } = useGlobalData();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [transactionStatus, setTransactionStatus] =
    React.useState<TransactionStatus>();
  const [errorData, setErrorData] = React.useState<string>("");
  const { control, handleSubmit } = useForm();

  const checkStatus = async (formData: any) => {
    setSubmitting(true);
    setErrorData("");
    const { ok, data, status, problem }: any =
      await rrrGeneratorService.checkTransactionStatus(formData.rrr);

    if (ok && status === 200) {
      const statusData = new TransactionStatus(data?.data);
      if (statusData) {
        setTransactionStatus({
          amount: statusData.amount,
          status: statusData.status,
          orderId: statusData.orderId,
          rrr: formData.rrr,
          transactionTime: statusData.transactionTime,
          message: statusData.message,
        });
      }
    } else if (!ok && problem === "CLIENT_ERROR") {
      // const err = JSON.parse(data.error);
      setErrorData(
        data?.error ||
          "No details available for provided RRR number, please verify RRR number and try again."
      );
    } else if (!ok && problem === "SERVER_ERROR") {
      // const err = JSON.parse(data.error);
      setErrorData(
        data?.error ||
          "No details available for provided RRR number, please verify RRR number and try again."
      );
    } else {
      setErrorData("Unexpected error, Please try again!");
    }

    setSubmitting(false);
  };

  const printStatusReceipt = async (data: any, heroTitle: string) => {
    const html: string = helpers.generateReceiptHtml(data, heroTitle, "status");
    await helpers.htmlStringToPdf(html, "status");
  };

  return (
    <>
      <Helmet>
        <title>Flopay | Check Payment Status</title>
        <meta name="description" content="#" />
      </Helmet>
      <main>
        <section className="login-area pt-100 pb-100">
          <div className="container">
            <div className="row">
              <div
                className={
                  !transactionStatus ? "col-md-8 offset-lg-2" : "col-md-12"
                }
              >
                <div className="basic-login">
                  <div className="row">
                    <div
                      className={!transactionStatus ? "col-md-12" : "col-md-6"}
                    >
                      <div className="checkbox-form">
                        <h3>Check Payment Status</h3>
                        <div className="row">
                          <div className="col-md-12">
                            <FormInput
                              name={"rrr"}
                              control={control}
                              label={"Enter RRR"}
                              placeholder={"Enter RRR"}
                              isRequired
                              type={"number"}
                              rules={{
                                maxLength: {
                                  value: 12,
                                  message:
                                    "RRR should not be more than 12 digits",
                                },
                                required: "RRR is required",
                              }}
                            />
                          </div>

                          {errorData && (
                            <div className="col-md-12">
                              <div className="alert alert-danger" role="alert">
                                {errorData}
                              </div>
                            </div>
                          )}

                          <div className="order-button-payment mt-20">
                            <FormButton
                              submitting={submitting}
                              onClick={handleSubmit(checkStatus)}
                              label={"Check Status"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {transactionStatus && (
                      <div className="col-md-6">
                        <div className="border p-4">
                          <div className="mb-4 pb-2 d-flex align-items-center border-bottom">
                            <img
                              src={configData?.logoUrl}
                              style={{ width: "50px", height: "auto" }}
                              alt=""
                            />
                            <h4
                              className="wow fadeInUp animated ml-2"
                              data-wow-delay="0.4s"
                            >
                              <span>{configData?.heroTitle}</span>
                            </h4>
                          </div>
                          <div className="d-flex  ">
                            <h6>Status Summary</h6>
                          </div>
                          <table className="table" id="rrr-table">
                            <tbody>
                              <tr>
                                <th>Order ID</th>
                                <td className="product_dimensions">
                                  {transactionStatus?.orderId}
                                </td>
                              </tr>
                              <tr>
                                <th>Amount</th>
                                <td className="product_dimensions">{`NGN ${transactionStatus?.amount}`}</td>
                              </tr>
                              <tr>
                                <th>RRR</th>
                                <td className="product_dimensions">
                                  {transactionStatus?.rrr}
                                </td>
                              </tr>

                              <tr>
                                <th>Status</th>
                                <td className="product_dimensions">
                                  {transactionStatus?.message}
                                </td>
                              </tr>
                              <tr>
                                <th>Time</th>
                                <td className="product_dimensions">
                                  {helpers.formatDateTime(
                                    transactionStatus
                                      ? transactionStatus.transactionTime
                                      : "",
                                    true
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="mt-4 border-top p-2 d-flex align-items-center justify-content-center ">
                            <p className="font-weight-bold">
                              Powered by Flopay
                            </p>
                          </div>
                        </div>
                        <div className="mt-2">
                          <button
                            type="button"
                            className="btn"
                            onClick={() =>
                              printStatusReceipt(
                                transactionStatus,
                                configData?.heroTitle
                              )
                            }
                          >
                            Print
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default PaymentStatus;
